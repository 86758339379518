<template>
  <div
      class="d-inline-block"
      v-permission="['show-logger']"
  >
    <template v-if="true">
      <el-button @click="start">
        <svg-icon
            class-name='time-icon'
            icon-class="play"
        />
        {{ $t('navbar.play') }}
      </el-button>
    </template>
    <template v-else>
      <el-button @click="pause">
        <svg-icon
            class-name='time-icon'
            icon-class="pause"
        />
        {{ $t('navbar.pause') }}
      </el-button>
      <el-button @click="stop">
        <svg-icon
            class-name='time-icon'
            icon-class="stop"
        />
        {{ $t('navbar.stop') }}
      </el-button>
    </template>
  </div>
</template>

<script>
import abstractForm from '../../mixin/index';
// import CustomButton from '../../UI/Button.vue'

export default {
  name: 'index',
  mixins: [abstractForm],
  // components: {CustomButton},
  methods: {
    start() {
      this.$store.dispatch('time/updateTime',
          {
            mode: 'play',
          }).then(this.showButtons);
      // this.$store.dispatch('generator/update', {field: 'fieldActivity', refresh: true})
      this.$store.commit('generator/UPDATE_ACTIVITY', true);
    },
    pause() {
      this.$store.dispatch('time/updateTime',
          {
            mode: 'pause',
          }).then(this.showButtons);
      this.$store.commit('generator/UPDATE_ACTIVITY', true);
    },
    stop() {
      this.$store.dispatch('time/updateTime',
          {
            mode: 'stop',
          }).then(this.showButtons);
      this.$store.commit('generator/UPDATE_ACTIVITY', true);
    },
  },
};
</script>

<style scoped>

</style>
